//PARAMS
@import "../../scss/params.scss";

/* LEFT */
///////////////////////////////////
.left-component {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-size: cover;
	transition: all .25s ease-in-out;
	overflow: hidden;
	position: relative;

	&::after {
		content: "";
		background: url("https://levialves.com.br/img/levi01.jpg") no-repeat top $bg;
		background-size: cover;
		filter: grayscale(100%);
		opacity: 0.2;
		width: 100%;
		height: 100vh;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	&.ativo {
		width: 50%;
	}

	.nav-buttom {
		margin-top: 40px;
		margin-left: 40px;
		margin-bottom: 40px;
		width: 31px;
		height: 24px;

		.open {
			display: flex;
			flex-direction: column;

			&:hover {
				.item {
					opacity: .5;
				}
			}

			.item {
				height: 3px;
				width: 30px;
				margin-bottom: 6px;
				background: #fff;
				transition: all 0.3s cubic-bezier(0.42, 0, 0.15, 1.49);
				transform-origin: 4px 1px;
				transition: all .25s ease-in-out;
			}
		}
	}

	.tit {
		margin-left: -180px;
		margin-bottom: 60px;
		transition: all .25s ease-in-out;
	}

	.redes {
		position: relative;
		margin-left: 40px;
		height: 0;
		overflow: hidden;
		margin-bottom: 0;
		transition: all .25s ease-in-out;

		ul {
			display: flex;
			flex-wrap: wrap;
		}

		.icon {
			background: no-repeat center;
			width: 42px;
			height: 42px;
			display: flex;
			margin-right: 20px;
			transition: all .25s ease-in-out;
			margin-top: 20px;

			&:hover {
				opacity: .5;
			}

			&.facebook {
				background-image: url("../img/facebook.png");
			}

			&.instagram {
				background-image: url("../img/instagram.png");
			}

			&.whatsapp {
				background-image: url("../img/whatsapp.png");
			}

			&.linkedin {
				background-image: url("../img/linkedin.png");
			}

			&.github {
				background-image: url("../img/github.png");
			}
		}
	}
}