//PARAMS
@import "../../scss/params.scss";

/* WORKS */
///////////////////////////////////
.works-component {
	overflow-x: auto;
	height: 100vh;

	.projects {
		margin: 0 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.thumb {
		overflow: hidden;
		width: 48.5%;
		position: relative;
		border: 1px solid $brancoOn;
		transition: all .25s ease-in-out;
		margin-bottom: 20px;

		&:hover {
			border: 1px solid $bg;

			.legend {
				opacity: 1;
			}

			img {
				transform: scale(1.2, 1.2);
				opacity: .8;
			}

		}

		img {
			display: flex;
			width: 100%;
			transition: all .25s ease-in-out;
			vertical-align: middle;
		}

		.legend {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .8);
			color: $brancoOn;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			opacity: 0;
			transition: all .25s ease-in-out;

			h4 {
				width: 150px;
			}

			small {
				display: flex;
				border-top: 0.5px solid $bg;
				margin-top: 15px;
				padding-top: 10px;
				width: 150px;
				font-weight: 200;
			}
		}
	}
}