//PARAMS
@import "../../scss/params.scss";

// RESPONSIVO
@media (max-width: 768px){
	.skills-component {
		&.ativo {
			height: 100% !important;
		}
	}
}