//PARAMS
@import "../../scss/params.scss";

/* NAV *////////////////////////////////////
.nav-component {

	.nav {
		background: #000;
		opacity: .8;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow:hidden;
    transition: all .25s ease-in-out;
    transform: scale(0, 0);

    &.ativo {
    	transform: scale(1, 1);
    }

		.close {
	    background: url(../img/close.png) no-repeat center;
	    width: 30px;
	    height: 30px;
	    display: block;
			margin-left: 40px;
			margin-top: 40px;

	    &:hover {
	    	opacity: .5;
	    }
		}

		ul {
	    text-align: center;
	    display: flex;
	    flex-direction: column;
	    height: 80vh;
	    justify-content: space-between;
	    align-items: center;
			
			li {
				&:nth-child(1) {
					a {
						margin-top: 40px; 
					}
				}
			}

			a {
				color: $brancoOn;
				text-decoration: none;
				font-size: 40px;
				font-weight: lighter;
				display: flex;
				justify-content: center;
				align-items: center;
		    width: 330px;

				&:before {
					content: "";
					width: 0;
					transition: all .25s ease-in-out;
				}

		    &:hover {
		    	opacity: .5;

		    	&:before {
						width: 30px;
		    		border-bottom: 1px solid #fff; 
		    	}
		    }
			}
		}
	}
}

