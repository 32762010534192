//PARAMS
@import "../../scss/params.scss";

/* CONTACT */
///////////////////////////////////
.who-i-m-component {
	overflow-x: auto;
	height: 100vh;

	&__img {
		width: 100%;
		height: 220px;
		display: flex;
		justify-content: end;
		margin-bottom: 50px;

		figure {
			overflow: hidden;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			margin-top: 20px;
			margin-right: 40px;
			border: 4px solid #fff;

			img {
				width: 100%;
			}
		}
	}

	ul {
		margin-right: 40px;
		margin-bottom: 20px;

		li {
			a {
				color: $brancoOn;
				text-align: right;
				display: block;
				font-weight: 200;
				text-decoration: none;
				line-height: 25px;

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}